exports.components = {
  "component---src-layout-template-1-g-solutions-js": () => import("./../../../src/layout/template1GSolutions.js" /* webpackChunkName: "component---src-layout-template-1-g-solutions-js" */),
  "component---src-layout-template-entreprise-js": () => import("./../../../src/layout/templateEntreprise.js" /* webpackChunkName: "component---src-layout-template-entreprise-js" */),
  "component---src-layout-template-general-js": () => import("./../../../src/layout/templateGeneral.js" /* webpackChunkName: "component---src-layout-template-general-js" */),
  "component---src-layout-template-geostudy-js": () => import("./../../../src/layout/templateGeostudy.js" /* webpackChunkName: "component---src-layout-template-geostudy-js" */),
  "component---src-layout-template-novinntec-js": () => import("./../../../src/layout/templateNovinntec.js" /* webpackChunkName: "component---src-layout-template-novinntec-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-etude-sol-contact-js": () => import("./../../../src/pages/etude-sol/contact.js" /* webpackChunkName: "component---src-pages-etude-sol-contact-js" */),
  "component---src-pages-etude-sol-devis-js": () => import("./../../../src/pages/etude-sol/devis.js" /* webpackChunkName: "component---src-pages-etude-sol-devis-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merci-js": () => import("./../../../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-partenaire-contact-partenaire-js": () => import("./../../../src/pages/partenaire/contact-partenaire.js" /* webpackChunkName: "component---src-pages-partenaire-contact-partenaire-js" */)
}

